// ZoomOnScroll.js
import React, { useEffect, useRef, useState } from 'react';

function ZoomOnScroll({ src, alt }) {
  const [scale, setScale] = useState(1); // Start at 1 (100%)
  const [isInView, setIsInView] = useState(false);
  const bannerRef = useRef(null);

  const handleScroll = () => {
    if (!isInView) return;

    const scrollPosition = window.scrollY;
    const bannerOffsetTop = bannerRef.current.offsetTop;
    const distanceScrolled = scrollPosition - bannerOffsetTop;

    // Increase scale based on the distance scrolled since entering view
    const scaleFactor = 1 + distanceScrolled * 0.0003; // Adjust to control zoom rate
    setScale(scaleFactor > 1.5 ? 1.5 : scaleFactor); // Limit max scale to 1.5
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsInView(entry.isIntersecting),
      { threshold: 0.1 } // Trigger when 10% of the banner is in view
    );

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isInView]);

  return (
    <div ref={bannerRef} style={{ width: '100vw', overflow: 'hidden', margin: '-20px' }}>
      <img 
        src={src} 
        alt={alt} 
        style={{ 
          width: '100vw', 
          height: 'auto', 
          transform: `scale(${scale})`, 
          transition: 'transform 0.1s ease-out' 
        }} 
      />
    </div>
  );
}

export default ZoomOnScroll;
