// ParallaxContainer.js
import React, { useEffect, useState } from 'react';

function ParallaxContainer({ children, className, direction = 'down' }) {
  const initialOffset = direction === 'down' ? -500 : 200; // Set initial offset based on direction
  const [offsetY, setOffsetY] = useState(initialOffset);

  const handleScroll = () => {
    const scrollFactor = direction === 'down' ? 0.1 : -0.1; // Adjust speed with scroll factor
    setOffsetY(initialOffset + window.pageYOffset * scrollFactor);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={className} style={{ transform: `translateY(${offsetY}px)` }}>
      {children}
    </div>
  );
}

export default ParallaxContainer;
